import React from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';

const Preload = ({ images }) => (
  <>
    {
      images.map((image) => (
        <link key={hash({ url: image })} rel="preload" href={image} as="image" type="image/jpeg" />
      ))
    }
  </>
);

Preload.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Preload;
