import React from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';

import Image from '../Image';
import css from './ImageRow.module.css';

const ImageRow = ({ row }) => (
  <div className={css.main}>
    {row.data.map((data) => (
      <Image key={hash(data)} data={data} />
    ))}
  </div>
);

ImageRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default ImageRow;
