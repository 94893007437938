import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import css from './LightDark.module.css';

import { Theme, Content } from '../../hooks';

const Emoji = ({ label, symbol }) => (
  <span
    className={css.emoji}
    role="img"
    aria-label={label && label}
    aria-hidden={label ? 'false' : 'true'}
  >
    {symbol}
  </span>
);

Emoji.propTypes = {
  label: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
};

const LightDark = () => {
  const { dark, setDark } = useContext(Theme);
  const { meta } = useContext(Content);

  return (
    <button
      type="button"
      className={`${css.button} ${dark && css.dark}`}
      onClick={() => setDark(!dark)}
    >
      <img
        className={css.image}
        alt={dark ? meta.darkIconAlt : meta.lightIconAlt}
        src={dark ? meta.darkIcon : meta.lightIcon}
      />
    </button>

  );
};

export default LightDark;
