import React from 'react';
import PropTypes from 'prop-types';

const TextRow = ({ row }) => <p>{row.data}</p>;

TextRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default TextRow;
