import React from 'react';
import pages from '../pages.module.css';

import Feature from '../../components/Feature';
import Portfolio from '../../components/Portfolio';

const Home = () => (
  <main className={pages.main}>
    <Feature />
    <Portfolio />
  </main>
);

export default Home;
