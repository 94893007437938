import React from 'react';
import PropTypes from 'prop-types';
import css from './SectionTitle.module.css';

const SectionTitle = ({
  title,
  subtitle,

}) => (
  <>
    {title !== ''
        && (
        <h2
          className={css.main}
          id={title.toLowerCase().replace(/\s+/g, '')}
        >
          {title}
        </h2>
        )}
    {subtitle !== ''
      && (
      <h4
        className={css.sub}
        id={subtitle.toLowerCase().replace(/\s+/g, '')}
      >
        {subtitle}
      </h4>
      )}
  </>
);

SectionTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SectionTitle.defaultProps = {
  title: '',
  subtitle: '',
};

export default SectionTitle;
