import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '../../../hooks';

const Image = ({ data, double }) => {
  const { margin } = useContext(Theme);

  return (
    <img
      style={{
        width: `calc(${data.width}% - ${double ? '0px' : `${margin}px`})`,
        height: 'auto',
        border: 'none',
      }}
      src={data.image}
      alt={data.alt}
    />
  );
};

Image.propTypes = {
  data: PropTypes.object.isRequired,
  double: PropTypes.bool,
};

Image.defaultProps = {
  double: false,
};

export default Image;
