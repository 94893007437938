import React from 'react';
import PropTypes from 'prop-types';
import css from './Section.module.css';

const Section = ({ type, children }) => {
  if (type === 'header') {
    return (
      <header
        className={css.head}
      >
        {children}
      </header>
    );
  }

  if (type === 'footer') {
    return (
      <footer
        className={css.foot}
      >
        {children}
      </footer>
    );
  }

  return (
    <section
      className={css.main}
    >
      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  type: PropTypes.string,
};

Section.defaultProps = {
  type: '',
};

export default Section;
