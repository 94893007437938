import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import css from './PrevNext.module.css';

const prevArrow = '<';
const nextArrow = '>';

const formatForLink = (title) => title.split(':')[0];

const PrevNext = ({ prev, next }) => (
  <div className={css.main}>
    <Link
      className={css.link}
      to={`/${prev.id}`}
    >
      {`${prevArrow} ${formatForLink(prev.title)}`}
    </Link>
    <Link
      className={css.link}
      to={`/${next.id}`}
    >
      {`${formatForLink(next.title)} ${nextArrow}`}
    </Link>
  </div>
);

PrevNext.propTypes = {
  prev: PropTypes.object.isRequired,
  next: PropTypes.object.isRequired,
};

export default PrevNext;
