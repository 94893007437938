import React from 'react';
import pages from '../pages.module.css';

import AboutMe from '../../components/AboutMe';
import Contact from '../../components/Contact';

const About = () => (
  <main className={pages.main}>
    <AboutMe />
    <Contact />
  </main>
);

export default About;
