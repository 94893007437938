import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import pages from '../pages.module.css';

import { Content } from '../../hooks';
import { prevNext, isCurrentProject } from './utils';
import Section from '../../components/Section';
import SectionTitle from '../../components/SectionTitle';
import Gallery from '../../components/Gallery';
import PrevNext from '../../components/PrevNext';

const Project = () => {
  const { portfolio } = useContext(Content);
  const { id } = useParams();

  const currentProject = portfolio.find(isCurrentProject(id));
  const { prev, next } = prevNext(currentProject.order, portfolio);

  return (
    <Section className={pages.main}>
      <SectionTitle
        className={pages.title}
        title={currentProject.title}
        subtitle={currentProject.subtitle}
      />
      <Gallery
        currentProject={currentProject}
        prev={prev}
        next={next}
      />
      <PrevNext prev={prev} next={next} />
    </Section>
  );
};

export default Project;
