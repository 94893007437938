import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import css from './Contact.module.css';

import { Content } from '../../hooks';
import Section from '../Section';

const Detail = ({
  name,
  link,
  info,
}) => (
  <li className={css.item}>
    <p className={css.name}>
      {name}
      :
    </p>
    <a
      className={css.link}
      href={`${link}${info}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`${info}`}
    </a>
  </li>
);

Detail.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

const Contact = () => {
  const { contact: { alt, image, links } } = useContext(Content);

  return (
    <Section>
      <div className={css.main}>
        <ul className={css.details}>
          {links.map(({ name, link, info }) => (
            <Detail
              key={hash({ name, link, info })}
              name={name}
              link={link}
              info={info}
            />
          ))}
        </ul>
        <img
          className={css.image}
          alt={alt}
          src={image}
        />
      </div>
    </Section>
  );
};

export default Contact;
