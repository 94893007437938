import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import css from './Thumb.module.css';

const Thumb = ({
  project: {
    id,
    thumbnail,
    thumbalt,
    title,
  },
}) => (
  <Link
    to={`/${id}`}
    className={css.main}
  >
    <img
      className={css.image}
      src={thumbnail}
      alt={thumbalt}
    />
    <div className={css.title}>{title}</div>
  </Link>
);

Thumb.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Thumb;
