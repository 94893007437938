import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import { Content, Theme } from '../hooks';

import Nav from '../components/Nav';

import About from '../pages/About';
import Home from '../pages/Home';
import Project from '../pages/Project';

import contentJson from '../content/_content';

const App = () => {
  const [dark, setDark] = useState(false);
  const {
    about, contact, feature, meta, portfolio,
  } = contentJson;

  document.title = meta.title;

  const [margin] = getComputedStyle(document.documentElement)
    .getPropertyValue('--margin')
    .match(/\d+/); // remove 'px'

  useEffect(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDark(true);
    }
  }, []);

  useEffect(() => {
    document.body.setAttribute('data-theme', `${dark ? 'dark' : 'light'}`);
  }, [dark]);

  const theme = useMemo(() => ({ dark, setDark, margin }), [dark, margin]);
  const content = useMemo(() => ({
    about, contact, feature, meta, portfolio,
  }), [about, contact, feature, meta, portfolio]);

  return (
    <Router>
      <Theme.Provider value={theme}>
        <Content.Provider value={content}>
          <Nav />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/:id" element={<Project />} />
          </Routes>
        </Content.Provider>
      </Theme.Provider>
    </Router>
  );
};

export default App;
