import React, { useContext } from 'react';
import css from './AboutMe.module.css';

import { Content } from '../../hooks';
import Section from '../Section';
import SectionTitle from '../SectionTitle';

const AboutMe = () => {
  const { about } = useContext(Content);

  return (
    <Section>
      <div className={css.main}>
        <img
          className={css.image}
          alt={about.alt}
          src={about.image}
        />
        <div className={css.summary}>
          <SectionTitle title={about.title} />
          {about.summary}
        </div>
      </div>
    </Section>
  );
};

export default AboutMe;
