import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import css from './Feature.module.css';

import { Content, Theme, useWindowDimensions } from '../../hooks';
import LightSwitch from '../LightSwitch';
import Section from '../Section';
import Preload from '../Preload';

const Feature = () => {
  const { dark } = useContext(Theme);
  const { feature } = useContext(Content);
  const { width: winWidth, height: winHeight } = useWindowDimensions();
  const [{ imgWidth, imgHeight }, getImageDims] = useState({ imgWidth: 0, imgHeight: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    getImageDims({ imgWidth: imageRef.current.width, imgHeight: imageRef.current.height });
  }, [imageRef, winWidth, winHeight]);

  const switchPosition = {
    top: `calc(-1.5em - 40px - ${imgHeight * 0.580}px)`,
    left: `${imgWidth * 0.315}px`,
  };

  return (
    <Section>
      <Preload images={[feature.imageDark, feature.imageLight]} />
      <div className={css.flex}>
        <img
          ref={imageRef}
          className={css.image}
          alt={dark ? feature.altDark : feature.altLight}
          src={dark ? feature.imageDark : feature.imageLight}
          onLoad={({ target }) => getImageDims({
            imgWidth: target.width,
            imgHeight: target.height,
          })}
        />
        <div
          className={css.switch}
          style={switchPosition}
        >
          <LightSwitch />
        </div>
      </div>
    </Section>
  );
};

export default Feature;
