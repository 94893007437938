import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import css from './Nav.module.css';

import { Content } from '../../hooks';
import Section from '../Section';
import LightDark from '../LightDark';

const Emoji = ({ label, symbol }) => (
  <span
    className={css.emoji}
    role="img"
    aria-label={label && label}
    aria-hidden={label ? 'false' : 'true'}
  >
    {symbol}
  </span>
);

Emoji.propTypes = {
  label: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
};

const Nav = () => {
  const { about } = useContext(Content);

  return (
    <Section type="header">
      <div className={css.header}>
        <Link className={css.link} to="/">
          <img src={about.nameImage} className={css.title} alt={about.name} />
        </Link>
        <nav className={css.list}>
          <Link className={css.link} to="/about">
            About
          </Link>
          <p>-</p>
          <Link className={css.link} to="/">
            Portfolio
          </Link>
          <p>-</p>
          <LightDark />
        </nav>
      </div>
      <hr className={css.hr} />
    </Section>
  );
};

export default Nav;
