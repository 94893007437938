import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import css from './Portfolio.module.css';

import { Content } from '../../hooks';
import { sortOrderDesending } from '../../utils';
import Section from '../Section';
import SectionTitle from '../SectionTitle';
import Thumb from '../Thumb';

export const Projects = ({ sortedPortfolio }) => (
  <ul className={css.projects}>
    {sortedPortfolio.map((project) => (
      <li className={css.thumb} key={hash(project)}>
        <Thumb project={project} />
      </li>
    ))}
  </ul>
);

Projects.propTypes = {
  sortedPortfolio: PropTypes.array.isRequired,
};

const Portfolio = () => {
  const { portfolio } = useContext(Content);
  const sortedPortfolio = portfolio.sort(sortOrderDesending);

  return (
    <Section className={css.main}>
      <SectionTitle title="Portfolio" className={css.title} />
      <Projects sortedPortfolio={sortedPortfolio} />
    </Section>
  );
};

export default Portfolio;
