export const isCurrentProject = (id) => (project) => project.id === id;

export const prevNext = (currentOrder, portfolio) => {
  const max = portfolio.length;
  const nextId = ((currentOrder - 1) + max) % max;
  const prevId = ((currentOrder + 1) + max) % max;
  const next = portfolio.find((project) => project.order === nextId);
  const prev = portfolio.find((project) => project.order === prevId);

  return { prev, next };
};
