import about from './about.json';
import contact from './contact.json';
import feature from './feature.json';
import meta from './meta.json';
import portfolio from './portfolio.json';

export default {
  ...about,
  ...contact,
  ...feature,
  ...meta,
  ...portfolio,
};
