import React, { useContext } from 'react';
import css from './LightSwitch.module.css';

import { Theme } from '../../hooks';

const LightSwitch = () => {
  const { dark, setDark } = useContext(Theme);
  const handleClick = () => setDark(!dark);

  return (
    <div
      role="button"
      tabIndex={0}
      className={css.button}
      onClick={handleClick}
      onKeyPress={handleClick}
      aria-label="light switch"
    />

  );
};

export default LightSwitch;
