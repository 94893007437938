import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const Swipe = ({ children, prev, next }) => {
  const navigate = useNavigate();
  const [start, setStart] = useState({});
  const [end, setEnd] = useState(0);
  const touchThreshold = 50;

  const handleTouchStart = ({ nativeEvent }) => setStart({
    x: nativeEvent.touches[0].clientX,
    y: nativeEvent.touches[0].clientY,
  });
  const handleTouchMove = ({ nativeEvent }) => setEnd({
    x: nativeEvent.touches[0].clientX,
    y: nativeEvent.touches[0].clientY,
  });

  const swipe = (s, e) => (
    (Math.abs(s.y - e.y) < touchThreshold)
    && (Math.abs(s.x - e.x) > touchThreshold)
    && (s.x - e.x)
  );

  const handleTouchEnd = () => {
    if (swipe(start, end) < 0) navigate(`/${prev.id}`, { replace: true });
    if (swipe(start, end) > 0) navigate(`/${next.id}`, { replace: true });
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};

Swipe.propTypes = {
  children: PropTypes.array.isRequired,
  prev: PropTypes.object.isRequired,
  next: PropTypes.object.isRequired,
};

export default Swipe;
