import React from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';

import {
  isDoubleRow,
  isImageRow,
  isTextRow,
} from './utils';
import { sortOrderAscending } from '../../utils';

import ImageRow from './components/ImageRow';
import Swipe from './components/Swipe';
import TallRow from './components/TallRow';
import TextRow from './components/TextRow';

const rowMapping = (row) => {
  if (isDoubleRow(row)) return <TallRow key={hash(row)} row={row} />;
  if (isImageRow(row)) return <ImageRow key={hash(row)} row={row} />;
  if (isTextRow(row)) return <TextRow key={hash(row)} row={row} />;
  return null;
};

const Gallery = ({ currentProject, prev, next }) => (
  <Swipe prev={prev} next={next}>
    {currentProject
      .rows
      .sort(sortOrderAscending)
      .map(rowMapping)}
  </Swipe>
);

Gallery.propTypes = {
  currentProject: PropTypes.object.isRequired,
  prev: PropTypes.object.isRequired,
  next: PropTypes.object.isRequired,
};

export default Gallery;
