import React from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';

import { isTallImage } from '../../utils';
import Image from '../Image';
import css from './TallRow.module.css';

const TallRow = ({ row }) => {
  const tallImage = row.data.find(isTallImage);
  const images = row.data.filter((rowData) => !isTallImage(rowData));

  const leftOrRight = tallImage
    && tallImage.tall === 'left'
    ? 'row'
    : 'row-reverse';

  return (
    <div
      key={hash(row)}
      className={css.main}
      style={{
        flexDirection: leftOrRight,
      }}
    >
      <Image tall data={tallImage} />
      <div className={css.double}>
        {images.map((data) => (
          <Image double key={hash(data)} data={data} />
        ))}
      </div>
    </div>
  );
};

TallRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default TallRow;
